/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* Only ~swiper/scss is enough for most cases, but you can uncomment as required. */
@import '~@ionic/angular/css/ionic-swiper';
@import "~swiper/scss";
@import "~swiper/css/effect-coverflow";
@import "~swiper/css/pagination";
@import "~swiper/css/effect-flip";
@import "~swiper/css/effect-cards";
// @import "~swiper/scss/autoplay";
// @import "~swiper/scss/keyboard";
// @import "~swiper/scss/scrollbar";
// @import "~swiper/scss/zoom";

@media only screen and (max-width: 769px) {
  ion-app {
    height: 100% !important;
  }
}

@media only screen and (min-width: 769px) {
  body {
    background: rgb(255, 47, 142);
    background: radial-gradient(circle, rgba(255, 47, 142, 1) 0%, rgba(179, 42, 168, 1) 29%, rgba(82, 35, 202, 1) 100%);

    ion-app {
      margin: auto;
      border: 5px solid #b5b5b5;
      border-radius: 15px;
      background: #fff;
    }
  }
}

////////////////////////////// HEIGHT ///////////////////////////////////
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  ion-app {
    width: 400px;
    --max-width: 400px !important;
  }
  .action-sheet-wrapper{
    --max-width: 390px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  ion-app {
    width: 420px;
   }

  .action-sheet-wrapper{
    --max-width: 410px !important;
  }
}

@media only screen and (min-width: 1681px) {
  ion-app {
    width: 440px;
  }

  .action-sheet-wrapper{
    --max-width: 430px !important;
  }
}

////////////////////////////// HEIGHT ///////////////////////////////////
@media only screen and (max-height: 700px) {
  ion-app {
    height: 95%;
  }
}

@media only screen and (min-height: 701px) AND (max-height: 800px) {
  ion-app {
    height: 680px;
  }
}

@media only screen and (min-height: 801px) AND (max-height: 900px) {
  ion-app {
    height: 750px;
  }
}

@media only screen and (min-height: 901px) {
  ion-app {
    height: 820px;
  }
}

.w-100 {
  width: 100%;
}

.d-inline-block {
  display: inline-block;
}

.position-relative {
  position: relative;
}


.ion-color-white {
  --ion-color-base: var(--ion-color-white) !important;
  --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
  --ion-color-contrast: var(--ion-color-white-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-white-shade) !important;
  --ion-color-tint: var(--ion-color-white-tint) !important;
}

ion-footer {
  background-color: var(--ion-color-light);
}

.weight-800 {
  font-weight: 800;
}

.weight-600 {
  font-weight: 600;
}

.light-header {
  text-transform: uppercase;
  text-align: center;
  color: var(--ion-color-medium);
  font-size: 0.9rem;
  font-weight: 600;

  &.no-margin {
    margin: 0
  }
}

.card-radius {
  border-radius: 20px;
}

.bg-button {
  font-size: 0.9rem;
  --background: linear-gradient(90deg, #5223CA, #FF2F8E);
}

.bg-class {
  font-size: 0.9rem;
  --background: linear-gradient(90deg, #0c9619, #12f328);
}

.custom-modal {
  --border-radius: 25px;

  .modal-wrapper {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    align-self: flex-end !important;
  }
}

.iframe-modal {
  --height: 100%;
  //  //--border-radius: 25px;
  //
  //  .modal-wrapper {
  //    border-bottom-left-radius: 0 !important;
  //    border-bottom-right-radius: 0 !important;
  //    align-self: flex-end;
  //  }
}

.feedback-modal {
  --border-radius: 20px;
  padding-bottom: 120px;
  //--height: 95%;

  .modal-wrapper {
    height: calc(100% - 80px) !important;
  }
}

.edit-question-modal{
  align-items: end;
  &::part(content) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}



.gdpr-modal {
  --height: 75%;
}

.custom-alert {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.cancelButton {
  color: #e0297d;
}

.alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 7px;
    display: block !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}


.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.answer ion-card, .question ion-card, .reaction-card ion-card {
  display: inline-block;
  max-width: 95%;
  position: relative;
  overflow: unset;
}

.answer-BUTTONS ion-card {
  min-width: 185px;
}

.confirm-card-button {
  position: absolute;
  bottom: -34px;
  left: 50%;
  border: 8px solid #fff;
  border-radius: 22px;
  width: 150px;
  height: 55px;
  margin-left: -75px;
  --background: linear-gradient(90deg, #5223CA, #FF2F8E);
}

.d-flex {
  display: flex;
  width: 100%;
}

.logo-container {
  display: inline-block;
  height: 80px;
  margin: auto;
  border-radius: 20px;
}


.logon-card {
  box-shadow: none;
  border-radius: 15px;
  margin: 0;

  & > ion-card-header h1,
  & > ion-card-header h2,
  & > ion-card-header h3,
  & > ion-card-header h4,
  & > ion-card-header h5,
  {
    margin: 0;
  }

  &.logon-card-accordion {
    &.extend {
      .chervon {
        transform: rotate(0deg);
      }
    }

    .chervon {
      float: right;
      transform: rotate(180deg);
    }

  }
}


.custom-popover {
  width: 0;

  ion-backdrop {
    display: none;
  }

  .popover-content {
    box-shadow: 0 0 5px 0 rgba(169, 169, 169, 0.75);
  }

  .popover-arrow {
    z-index: 1000000;
  }

  &.custom-popover-position {
    .popover-content {
      top: unset !important;
      bottom: 70px !important;
      left: 5px !important;
    }

    .popover-arrow {
      top: unset !important;
      bottom: 65px !important;
      left: 30px !important;
    }
  }
}

.hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.gdpr-content {
  color: #9d9fa6;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.skip-alert {
  text-align: center;
  --backdrop-opacity: 0;
  align-items: flex-end;
  .cancel-btn {
    background-color: #C1C1C2;
    border-radius: 20px;
    color: #fff;
    padding-left: 20pt;
    padding-right: 20pt;
    text-transform: uppercase;
  }

  .confirm-btn {
    background-color: #5223CA;
    border-radius: 20px;
    color: #fff;
    padding-left: 20pt;
    padding-right: 20pt;
  }

  .alert-button-group {
    justify-content: center;
    padding-bottom: 15px;
    padding-top: 0;
  }

  .alert-wrapper {
    border-radius: 20px;
    margin-bottom: 80px;
  }
  .ion-overlay-wrapper {
    background: white;
  }

  .sc-ion-alert-ios button{
    flex: unset;
    min-width: unset;
    margin: 0 15px;
  }
}

.apply-alert {
  text-align: center;
  --backdrop-opacity: 0;
  --max-width:340px;
  align-items: flex-end;
  .cancel-btn {
    background-color: #C1C1C2;
    border-radius: 20px;
    color: #fff;
    padding-left: 20pt;
    padding-right: 20pt;
    text-transform: uppercase;
  }

  .confirm-btn {
    background: linear-gradient(110deg, #0DC922,#12F328);
    border-radius: 20px;
    color: #fff;
    padding-left: 20pt;
    padding-right: 20pt;
  }

  .alert-button-group {
    justify-content: center;
    position: relative;
    bottom: 40px;
  }

  .alert-wrapper {
    border-radius: 20px;
    margin-bottom: 80px;
    width: 100%;
  }

  .alert-message{
    font-size: 12px;
    position: relative;
    bottom: -60px;
  }
  .alert-message.sc-ion-alert-ios{
    bottom: -50px !important;
  }
  .alert-title{
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }

  .ion-overlay-wrapper {
    background: white;
  }

  .sc-ion-alert-ios button{
    flex: unset;
    min-width: unset;
    margin: 0 15px;
  }
}

.required-question {
  --background: #fff;
  --color: #000;
  --ion-safe-area-bottom: 72px;
  --width: 80%;
}



.footer-md::before{
  background-image: unset !important;
  content: none;
}

ion-app{
  background: #fff;
}
.action-sheet-icon.sc-ion-action-sheet-ios.ios{
  position: absolute;
  left: 0;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios{
  padding-left: 30px !important;
}


.alert-radio-label.sc-ion-alert-md{
  white-space: normal !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}
